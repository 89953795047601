<template>
  <div class="questionnaires">
    <ConfirmationDialog ref="confirmationDialog" />
    <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <div v-else>
      <div class="mb-2">
        <v-btn color="primary" title="Imprimir" icon @click="print">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </div>

      <div
        class="layout-body"
        v-for="(layout, index) in layouts"
        :key="index"
        v-show="step == index"
      >
        <div :class="['questionnaires-title', { first: index === 0 }]">
          <v-row>
            <v-col class="text-subtitle-2 text-center">
              Paciente: {{ patient_name }}
            </v-col>
          </v-row>
        </div>
        <div class="questionnaires-body">
          <v-form class="pa-4">
            <v-row class="mb-4">
              <v-col class="text-center text-subtitle-2">{{
                layout.name
              }}</v-col>
            </v-row>
            <div
              v-for="(category, index) in layout.categories"
              :key="`category-${index}`"
            >
              <v-row no-gutters class="mb-4">
                <v-col class="font-14 font-weight-medium section-title">{{
                  category.name
                }}</v-col>
              </v-row>

              <v-row
                no-gutters
                v-for="(field, fieldIndex) in category.fields"
                :key="`field-${fieldIndex}`"
              >
                <QuestionnaireField
                  :field="field"
                  :disabled="disabled"
                ></QuestionnaireField>
              </v-row>
            </div>
            <v-row>
              <v-col v-if="index > 0" class="text-right">
                <v-btn outlined color="primary" @click="prev"> Anterior </v-btn>
              </v-col>
              <v-col :class="index == 0 ? 'text-center' : 'text-left'">
                <v-btn
                  v-if="index != layouts.length - 1"
                  color="primary"
                  :loading="saving"
                  @click="next"
                >
                  Próximo
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  :disabled="disabled"
                  :loading="saving"
                  @click="finalize"
                >
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import QuestionnaireEnum from '@/enumerations/questionnaires_enum'
import ConfirmationDialog from './confirmation_dialog'
import QuestionnaireField from './questionnaire_field'
import moment from 'moment'
export default {
  name: 'Questionnaires',
  components: { QuestionnaireField, ConfirmationDialog },
  data: () => ({
    loading: false,
    saving: false,
    layouts: [],
    patient_name: null,
    step: 0,
  }),

  mounted() {
    this.fetch()
  },

  computed: {
    disabled() {
      const layout = this.layouts[this.step]
      return layout?.status === QuestionnaireEnum.status.finished
    },
  },

  methods: {
    print() {
      window.print()
    },
    next() {
      if (this.step < this.layouts.length - 1) {
        if (this.disabled) {
          this.step = this.step + 1
        } else {
          this.save(() => {
            this.step = this.step + 1
            this.saveStatus(QuestionnaireEnum.status.pending)
          })
        }
      }
    },

    prev() {
      if (this.step > 0) {
        this.step = this.step - 1
      }
    },

    positiveNumberRule(val) {
      if (val < 0) return 'Entre com um número positivo'
      return true
    },

    integerNumberRule(val) {
      if (!/^\d+$/.test(val)) return 'Entre com um número inteiro'
      return true
    },

    _findPropertyStartingWith(object, prefix) {
      for (const key in object) {
        if (key.startsWith(prefix)) {
          return key
        }
      }
      return null
    },

    _parseField(fields, values) {
      let result = []
      const subFields = []

      const findValue = field => {
        const prop = QuestionnaireEnum.fields.find(
          x => x.cod == field.tipo_campo
        )?.response_field

        const valueObj = values.find(
          x => x.codigo_banco_dado_campo == field.codigo
        )

        const value = valueObj?.[prop] || null

        if (valueObj) {
          return {
            value: value,
            prop: prop,
          }
        }

        return {
          value: null,
          prop: prop,
        }
      }

      const mountField = data => {
        const valueObj = findValue(data)
        return {
          id: data.codigo,
          name: data.nome,
          sub: data.sub == null ? false : data.sub,
          description: data.descricao,
          field_type: data.tipo_campo,
          display_type: data.tipo_exibicao,
          complement: data.complemento,
          decimal: data.quantidade_casa_decimal,
          round: data.arredonda,
          default_value: data.valor_default,
          maximum: data.valor_maximo,
          minimum: data.valor_minimo,
          age_less_that: data.idade_menor_que,
          age_greater_that: data.idade_maior_que,
          sex: data.sexo,
          field_id: data.codigo_banco_dado_campo,
          value_prop: valueObj.prop,
          value: valueObj.value,
          open: false,
          options: data.banco_dados_campo_dominio.map(option => ({
            id: option.codigo,
            value: option.valor,
            warn: option.valor_alerta,
            default_value: option.valor_default,
            rules: option.banco_dados_campo_logica.map(rule => ({
              id: rule.codigo,
              action: rule.acao,
              question_id: rule.exibir_pergunta,
              text: rule.texto_preparo,
              field:
                rule.exibir_pergunta != null
                  ? (() => {
                      const fieldIndex = fields.findIndex(
                        f => f.codigo === rule.exibir_pergunta
                      )
                      if (fieldIndex >= 0) {
                        const fieldData = fields[fieldIndex]
                        subFields.push(fieldData.codigo)
                        return mountField(fieldData)
                      }
                      return null
                    })()
                  : null,
            })),
          })),
        }
      }

      fields.forEach(fieldOptions => {
        const field = mountField(fieldOptions)
        result.push(field)
      })

      // remove subfields
      result = result.filter(r => !subFields.includes(r.id))
      return result
    },

    _parseLayout(layout, values, process, response_id, fato_gerador) {
      const result = {
        id: layout.codigo,
        status: process.status_resposta,
        editable: process.permite_alteracao,
        name: layout.nome,
        response_id: response_id,
        fato_gerador: fato_gerador,
        categories: layout.banco_dados_layout_categoria.map(category => ({
          id: category.codigo,
          name: category.nome,
          fields: this._parseField(category.banco_dados_campo, values),
        })),
      }

      this.layouts.push(result)
    },

    fetchQuestion(codigo_resposta) {
      return Api.core.questionarios.perguntas({
        codigo_banco_dado_resposta: codigo_resposta,
      })
    },

    parseDate(value) {
      return moment(value).format('DD/MM/YYYY')
    },

    saveStatus(status) {
      const token = this.$route.params.token
      return Api.core.questionnaires
        .saveStatus(token, {
          status_resposta: status,
        })
        .catch(error => {
          if (error.response) {
            this.$toast.show('Erro ao atualizar status', 'error')
          } else {
            throw error
          }
        })
    },

    finalize() {
      this.save(() => {
        this.saveStatus(QuestionnaireEnum.status.finished)
        this.$refs.confirmationDialog.show()
        setTimeout(() => {
          this.$router.go(0) // refresh page
        }, 5000)
      })
    },

    async save(afterSave) {
      const token = this.$route.params.token
      try {
        this.saving = true
        const layout = this.layouts[this.step]

        const values = []

        const _addValue = field => {
          const value = { codigo_banco_dado_campo: field.id }
          value[field.value_prop] = field.value

          values.push(value)

          // get recursive filds
          field.options.forEach(option => {
            option.rules.forEach(rule => {
              if (rule.field != null) {
                _addValue(rule.field)
              }
            })
          })
        }

        // get values
        layout.categories.forEach(category => {
          category.fields.forEach(field => {
            _addValue(field)
          })
        })

        const payload = {
          processo_resposta: {
            codigo_banco_dado_resposta: layout.response_id,
            fato_gerador: layout.fato_gerador, // fixed
            layouts: [
              {
                codigo: layout.id,
                valores: values,
              },
            ],
          },
        }

        await Api.core.questionnaires.save(token, payload)

        if (typeof afterSave === 'function') {
          await afterSave()
        }
      } catch (error) {
        if (error.response) {
          this.$toast.show('Erro ao salvar questionário', 'error')
        } else {
          throw error
        }
      } finally {
        this.saving = false
      }
    },

    async fetch() {
      try {
        this.loading = true
        const token = this.$route.params.token

        const processResponse = await Api.core.questionnaires.fetch(token)

        // const process = processResponse.data
        const layouts = processResponse.data.perguntas.banco_dados_layout

        for (const layout of layouts) {
          const layoutValues = processResponse.data.respostas.layouts.find(
            x => x.codigo == layout.codigo
          ).valores

          if (processResponse.data.agendamento) {
            this.patient_name = processResponse.data.agendamento.paciente.nome
          }

          this._parseLayout(
            layout,
            layoutValues,
            processResponse.data.respostas,
            processResponse.data.respostas.codigo_banco_dado_resposta,
            processResponse.data.respostas.fato_gerador
          )
        }
      } catch (err) {
        if (err.response) {
          if (err?.response?.status != 500) {
            this.$toast.show(err.response.data.errors.join(','), 'error')
          } else {
            this.$toast.show('Erro ao buscar dados', 'error')
          }
        } else {
          throw err
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
@media print {
  .questionnaires-body {
    background: white !important;
    border-radius: unset;
  }

  .layout-body > .questionnaires-title {
    display: none;
  }

  .layout-body > .questionnaires-title.first {
    display: block !important;
  }

  .layout-body {
    display: block !important;
    // page-break-after: always;
  }

  .v-btn {
    display: none;
  }
}
.questionnaires {
  flex-direction: column;
  padding: 1rem;
  width: 90%;
  max-width: 748px;

  .loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .text-h6 {
    font-size: 2.125rem !important;
  }

  .text-subtitle-2 {
    font-size: 1.125rem !important;
  }

  .section-title {
    color: var(--v-primary);
  }
}

.questionnaires-title {
  margin-bottom: 1rem;
}

.questionnaires-body {
  background: #f4f4f8;
  border-radius: 1.875rem;
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  // .root-container {
  //   padding: 25px 17px !important;
  //   margin-top: 50px;
  // }
}
</style>
