<template lang="html">
  <v-dialog fullscreen persistent v-model="dialog">
    <div class="confirmation py-4">
      <div
        :class="[
          'px-3 mb-0 pb-0',
          'font-weight-bold',
          { 'text-center': $vuetify.breakpoint.smAndDown },
          'text-h5',
        ]"
      >
        Questionário enviado com sucesso!
      </div>
      <v-icon color="primary" class="my-8">mdi-check-circle-outline</v-icon>
      <img :src="organization.logo" alt="logo" />
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'QuestionnaireConfirmationDialog',
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),
  },

  methods: {
    show() {
      this.dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background: #f4f4f8 !important;
  height: 100%;
  overflow-y: auto;

  img {
    max-height: 45px;
  }

  .v-icon {
    font-size: 300px;
  }
}
</style>
