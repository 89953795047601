<template>
  <div class="questionnaire-field">
    <!-- List de opções -->
    <v-col v-if="field.field_type == fieldType.LIST">
      <div class="mb-2 font-14 font-weight-medium">
        {{ field.name }}
      </div>
      <v-radio-group
        :disabled="disabled"
        v-model="field.value"
        class="mt-0 pt-0"
      >
        <v-radio
          v-for="(option, optionIndex) in field.options"
          :key="`option-${optionIndex}`"
          :label="option.value"
          :value="option.value"
        ></v-radio>
      </v-radio-group>
    </v-col>

    <v-col v-if="field.field_type == fieldType.CHECKBOX">
      <v-checkbox
        :disabled="disabled"
        dense
        outlined
        v-model="field.value"
        :label="field.name"
      ></v-checkbox>
    </v-col>

    <!-- texto curto -->
    <v-col v-if="field.field_type == fieldType.SHORT_TEXT">
      <div class="mb-2 font-14 font-weight-medium">
        {{ field.name }}
      </div>
      <v-text-field
        v-model="field.value"
        :disabled="disabled"
        background-color="#fff"
        dense
        outlined
      >
      </v-text-field>
    </v-col>

    <!-- texto longo -->
    <v-col v-if="field.field_type == fieldType.LONG_TEXT">
      <div class="mb-2 font-14 font-weight-medium">
        {{ field.name }}
      </div>
      <v-textarea
        v-model="field.value"
        :disabled="disabled"
        background-color="#fff"
        dense
        outlined
      >
      </v-textarea>
    </v-col>

    <!-- número inteiro -->
    <v-col v-if="field.field_type == fieldType.INT">
      <div class="mb-2 font-14 font-weight-medium">
        {{ field.name }}
      </div>
      <v-text-field
        dense
        type="number"
        :disabled="disabled"
        step="1"
        min="0"
        background-color="white"
        v-model="field.value"
        :rules="[$rules.int, $rules.greaterThanZero]"
        outlined
        name="username"
      ></v-text-field>
    </v-col>

    <!-- escala -->
    <!-- <v-col v-if="field.field_type == 10">
                <div class="mb-2 font-14 font-weight-medium">{{ field.name }}</div>
                <v-slider thumb-label="always"></v-slider>
              </v-col> -->

    <!-- data -->
    <v-col v-if="field.field_type == fieldType.DATE">
      <div class="mb-2 font-14 font-weight-medium">
        {{ field.name }}
      </div>
      <v-menu
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        v-model="field.open"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            background-color="white"
            persistent-hint
            append-icon="mdi-calendar"
            v-bind="attrs"
            :value="field.value | njDate"
            :disabled="disabled"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="field.value"
          @input="field.open = false"
          no-title
        ></v-date-picker>
      </v-menu>
    </v-col>

    <!-- subfields -->
    <QuestionnaireSubFields
      :field="field"
      :disabled="disabled"
    ></QuestionnaireSubFields>
  </div>
</template>
<script type="text/javascript">
import { MAP } from '@/enumerations/questionnaires_enum'
export default {
  name: 'QuestionnaireField',
  components: {
    QuestionnaireSubFields: () => import('./questionnaire_sub_fields'),
  },
  props: {
    field: {
      type: Object,
      default: () => ({ value: null }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldType: MAP,
    }
  },
}
</script>
<style scoped lang="scss">
.questionnaire-field {
  width: 100%;

  .v-radio {
    width: fit-content;
  }
}
</style>
